<template>
  <v-container fluid>
    <event-list v-if="displayMode == 'list'"></event-list>
    <event-table v-else-if="displayMode == 'table'"></event-table>
    <event-calendar v-else></event-calendar>
  </v-container>
</template>

<script>
import EventList from '@/components/events/EventList.vue'
import EventCalendar from '@/components/events/EventCalendar.vue'
import EventTable from '@/components/events/EventTable.vue'
import { useUserStore } from '@/stores/user'

export default {
  name: 'Events',
  components: { EventList, EventCalendar, EventTable },
  props: {},
  data() {
    return {}
  },
  computed: {
    displayMode() {
      return useUserStore().settings.eventDisplay || 'list'
    },
  },
  watch: {},
  mounted() { },
  beforeCreate() {
    if (this.$route.params.year === undefined) {
      this.$router.push({ name: this.$route.name, params: { year: (new Date()).getFullYear() } })
    }
  },
  methods: {},
}
</script>

<style lang="scss">
.sticky-toolbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.year-select {
  min-width: 85px;
  max-width: 110px;
  margin-right: 5px;
}
</style>
