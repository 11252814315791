<template>
  <v-card v-scroll="onScroll">
    <v-app-bar
      flat
      color="grey lighten-3"
      :dense="!toolbarIsSticky"
      :fixed="toolbarIsSticky"
      :class="{
        'sticky-toolbar': toolbarIsSticky,
      }"
      :style="{
        'padding-left':
          toolbarIsSticky && $vuetify.breakpoint.smAndUp
            ? `${$vuetify.application.left + 10}px`
            : 0,
      }"
    >
      <span class="subheading mr-2" v-if="$vuetify.breakpoint.smAndUp"
        >Jahr:</span
      >

      <v-select
        :items="meta.years"
        v-model="selected_year"
        solo
        flat
        hide-no-data
        hide-details
        class="year-select"
        :dense="!toolbarIsSticky"
        v-if="$vuetify.breakpoint.smAndUp || !searchActive"
      />

      <v-spacer v-if="!searchActive" />

      <v-text-field
        label="Tour suchen ..."
        solo
        flat
        hide-no-data
        hide-details
        :dense="!toolbarIsSticky"
        append-icon="mdi-magnify"
        v-model="searchInput"
        style="min-width: 135px"
        @focus="searchActive = true"
        @blur="searchActive = false"
        class="ml-2"
      />

      <!-- The event display settings dialog renders as a button by default which opens the dialog.
           Optionally the activator slot can be used (the same as for vuetify dialogs) -->
      <event-display-settings-dialog />

      <v-btn
        v-if="hasRole('manager') && $vuetify.breakpoint.smAndUp"
        :to="{ name: 'EventEdit', params: { id: 'new' } }"
        icon
        dark
        color="primary"
        data-cy="add-event"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>

      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon text><v-icon>mdi-dots-vertical</v-icon></v-btn>
        </template>
        <v-list>
          <v-list-item
            v-if="hasRole('manager') && !$vuetify.breakpoint.smAndUp"
            :to="{ name: 'EventEdit', params: { id: 'new' } }"
          >
            <v-list-item-title>Neue Tour</v-list-item-title>
          </v-list-item>
          <v-list-item @click="exportEvents">
            <v-list-item-title>Touren exportieren</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-progress-linear v-if="loading" :indeterminate="true" />
    <event-expansion-panel
      v-if="!loading && filteredEvents.length"
      :events="filteredEvents"
      :month-headers="true"
    />
    <v-card-text v-if="!loading && filteredEvents.length == 0"
      >Keine Touren gefunden.</v-card-text
    >
  </v-card>
</template>

<script>
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'
import { mapState } from 'pinia'

import downloadService from '@/services/download'
import { handleApiError } from '@/utils.js'
import EventDisplaySettingsDialog from '@/components/events/EventDisplaySettingsDialog.vue'
import EventExpansionPanel from '@/components/events/EventExpansionPanel.vue'
import { useUserStore } from '@/stores/user'
import { useEventStore } from '@/stores/event'
import { useAuthStore } from '@/stores/auth'


export default {
  name: 'Events',
  components: { EventDisplaySettingsDialog, EventExpansionPanel },
  props: {},
  data() {
    return {
      loading: false,
      selected_year: (new Date()).getFullYear(),
      searchText: '',
      searchActive: false,
      toolbarSwitchEnabled: true,
      toolbarIsSticky: false,
      lastScroll: null,
    }
  },
  computed: {
    filteredEvents() {
      let events = this.getByYear(this.selected_year)
      // Apply filter if any
      if (this.searchText) {
        events = events.filter(e => {
          const st = this.searchText.toLowerCase()
          return e.title.toLowerCase().indexOf(st) > -1 || // Partial match of title
            (e.organisation && e.organisation.toLowerCase() === st) // Exact match of organisation
        })
      }
      return events
    },
    searchInput: { // Debounced setter for search input
      get() { return this.searchText },
      set: debounce(function (newVal) { this.searchText = newVal.trim() }, 250),
    },
    ...mapState(useUserStore, { eventFilter: store => store.settings.eventFilter }),
    ...mapState(useEventStore, ['loaded_year', 'meta', 'getByYear']),
    ...mapState(useAuthStore, ['hasRole']),
  },
  watch: {
    selected_year(newVal) {
      this.$router.push({ name: this.$route.name, params: { year: newVal } }).catch(error => {
        if (error.name !== 'NavigationDuplicated') {
          throw error
        }
      })
      this.updateEvents()
    },
    eventFilter: {
      handler(val) { this.updateEvents() },
      deep: true,
    },
  },
  mounted() {
    this.selected_year = parseInt(this.$route.params.year)
    this.updateEvents()
  },
  methods: {
    updateEvents() {
      this.loading = true
      useEventStore().fetchEvents({ year: this.selected_year, filters: this.eventFilter })
        .then(() => {
          this.loading = false
          this.$root.$emit('triggerScroll')
        }).catch(error => {
          handleApiError(error, 'Fehler beim Laden der Daten')
        })
    },
    exportEvents() {
      downloadService.download('/export/events/excel',
        { year: this.selected_year, ...this.eventFilter },
      ).catch(error => {
        handleApiError(error, 'Fehler beim Laden der Datei')
      })
    },
    onScroll: throttle(function () {
      // Remove the hash, it's no longer valid after a scroll
      if (this.$route.hash) {
        this.$router.replace({ name: this.$route.name, params: { year: this.$route.params.year } })
      }

      if (this.searchActive) return

      const scrollThreshold = 200
      const currentScroll = window.pageYOffset || document.documentElement.scrollTop

      if (this.lastScroll === null) {
        this.lastScroll = currentScroll
      }

      if (Math.abs(this.lastScroll - currentScroll) < scrollThreshold) return

      this.toolbarIsSticky = (this.lastScroll - currentScroll) < scrollThreshold
      this.lastScroll = currentScroll
    }, 150),
  },
}
</script>

<style>
.sticky-toolbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.year-select {
  min-width: 85px;
  max-width: 110px;
  margin-right: 5px;
}
</style>
