<template>
  <v-card>
    <v-app-bar flat color="grey lighten-3" :dense="false" :fixed="false">
      <span class="subheading mr-2" v-if="$vuetify.breakpoint.smAndUp"
        >Jahr:</span
      >

      <v-select
        :items="meta.years"
        v-model="selected_year"
        solo
        flat
        hide-no-data
        hide-details
        class="year-select"
        :dense="!toolbarIsSticky"
        v-if="$vuetify.breakpoint.smAndUp || !searchActive"
      />

      <v-spacer />

      <!-- The event display settings dialog renders as a button by default which opens the dialog -->
      <event-display-settings-dialog />

      <v-btn
        v-if="hasRole('manager') && $vuetify.breakpoint.smAndUp"
        :to="{ name: 'EventEdit', params: { id: 'new' } }"
        icon
        dark
        color="primary"
        data-cy="add-event"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>

      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon text><v-icon>mdi-dots-vertical</v-icon></v-btn>
        </template>
        <v-list>
          <v-list-item
            v-if="hasRole('manager') && !$vuetify.breakpoint.smAndUp"
            :to="{ name: 'EventEdit', params: { id: 'new' } }"
          >
            <v-list-item-title>Neue Tour</v-list-item-title>
          </v-list-item>
          <v-list-item @click="exportEvents">
            <v-list-item-title>Touren exportieren</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-progress-linear v-if="loading" :indeterminate="true" />

    <v-data-table
      :headers="headers"
      :items="displayEvents"
      :items-per-page="200"
      :footer-props="{
        'items-per-page-options': [25, 50, 100, 200, 500],
      }"
    >
      <template v-slot:item.organisation="{ item }">
        <org-icon :organisation="item.organisation"></org-icon>
      </template>
      <template v-slot:item.main_guide="{ item }">
        {{ mainGuideName(item) }}
      </template>
      <template v-slot:item.kind="{ item }"
        >{{ getEventKindByValue(item.kind) }}
      </template>
      <template v-slot:item.participants="{ item }"
        >{{ item.participants.length }}
      </template>

      <template v-slot:item.download="{ item }">
        <v-btn :href="item.download" small icon v-if="item.download">
          <v-icon>mdi-file-download</v-icon>
        </v-btn>
      </template>

      <template v-slot:item.feedback="{ item }">
        <v-icon v-if="item.needs_feedback">mdi-check</v-icon>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn icon :to="{ name: 'EventView', params: { id: item.id } }">
          <v-icon class="primary--text">mdi-account-multiple</v-icon>
        </v-btn>
        <v-btn
          icon
          :to="{ name: 'EventEdit', params: { id: item.id } }"
          :disabled="$ability.cannot('edit', 'Event')"
        >
          <v-icon class="primary--text">mdi-pencil</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapState } from 'pinia'

import downloadService from '@/services/download'
import { handleApiError } from '@/utils.js'
import EventDisplaySettingsDialog from '@/components/events/EventDisplaySettingsDialog.vue'
import { usePersonStore } from '@/stores/person'
import { useUserStore } from '@/stores/user'
import { useEventStore } from '@/stores/event'
import { useAuthStore } from '@/stores/auth'

export default {
  name: 'Events',
  components: { EventDisplaySettingsDialog },
  props: {},
  data() {
    return {
      loading: false,
      selected_year: (new Date()).getFullYear(),
      toolbarSwitchEnabled: true,
      toolbarIsSticky: false,
      headers: [
        { text: 'Gruppe', value: 'organisation', sortable: false, width: 70 },
        { text: 'Tour', value: 'title', sortable: true },
        { text: 'Datum', value: 'date', sortable: false },
        { text: 'Art', value: 'kind', sortable: true },
        { text: 'Schwierigkeit', value: 'difficulty', sortable: false },
        { text: 'Hauptleiter', value: 'main_guide', sortable: false },
        { text: 'Anz. TN', value: 'participants', sortable: false, align: 'center' },
        { text: 'Tourenblatt', value: 'download', sortable: false, align: 'center' },
        { text: 'Rückmeldung', value: 'feedback', sortable: false },
        { text: 'Aktionen', value: 'actions', sortable: false, width: 120 },
      ],
    }
  },
  computed: {
    displayEvents() {
      const events = this.getByYear(this.selected_year)
      return events
    },
    ...mapState(useEventStore, ['loaded_year', 'meta', 'getByYear', 'getEventKindByValue']),
    ...mapState(useUserStore, { eventFilter: store => store.settings.eventFilter }),
    ...mapState(useAuthStore, ['hasRole']),
    ...mapState(usePersonStore, { getPersonById: 'getById' }),
  },
  watch: {
    selected_year(newVal) {
      this.$router.push({ name: this.$route.name, params: { year: newVal } }).catch(error => {
        if (
          error.name !== 'NavigationDuplicated' &&
          !error.message.includes('Avoided redundant navigation to current location')
        ) {
          console.log(error)
        }
      })
      this.updateEvents()
    },
    eventFilter: {
      handler(val) { this.updateEvents() },
      deep: true,
    },
  },
  mounted() {
    this.selected_year = parseInt(this.$route.params.year)
    this.updateEvents()
  },
  methods: {
    updateEvents() {
      this.loading = true
      useEventStore().fetchEvents({ year: this.selected_year, filters: this.eventFilter })
        .then(() => {
          this.loading = false
        }).catch(error => {
          handleApiError(error, 'Fehler beim Laden der Daten')
        })
    },
    exportEvents() {
      downloadService.download('/export/events/excel',
        { year: this.selected_year, ...this.eventFilter },
      ).catch(error => {
        handleApiError(error, 'Fehler beim Laden der Datei')
      })
    },
    mainGuideName(item) {
      const person = this.getPersonById(item.main_guide)
      if (!person) {
        return ''
      }
      return person.name
    },
  },
}
</script>

<style>
.sticky-toolbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.year-select {
  min-width: 85px;
  max-width: 110px;
  margin-right: 5px;
}
</style>
